<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card>
                <b-row>
                    <b-col md="6" lg="4" class="mb-3">
                        <h4 class="mb-2">Background <tooltip-with-image img="/img/welcome/background.png"/></h4>
                        <BackgroundSelector ref="BackgroundSelectorBackground" v-model="config.main_background"/>
                    </b-col>
                    <b-col md="6" lg="4" class="mb-3">
                        <h4 class="mb-2">Window Background <tooltip-with-image img="/img/welcome/window_background.png"/></h4>
                        <BackgroundSelector ref="BackgroundSelectorWindowBackground" v-model="config.window_background"/>
                    </b-col>
                    <b-col>
                        <h4 class="mb-2">Other</h4>
                        <b-form-group>
                            <label>Logo position: <tooltip-with-image img="/img/welcome/logo.png"/></label>
                            <v-select
                                v-model="config.logo_position"
                                :reduce="item => item.id"
                                label="name"
                                :clearable="false"
                                :options="logoPositions"
                            />
                        </b-form-group>
                        <b-form-group>
                            <label>Logo image:</label>
                            <ImageSelector ref="ImageSelectorLogo" v-model="config.logo_image"/>
                        </b-form-group>

                        <b-form-group>
                            <label>Primary text color: <tooltip-with-image img="/img/welcome/primary.png"/></label>
                            <ColorWithOpacitySelector v-model="config.primary_text_color"/>
                        </b-form-group>
                        <b-form-group>
                            <label>Secondary text color: <tooltip-with-image img="/img/welcome/secondary.png"/></label>
                            <ColorWithOpacitySelector v-model="config.secondary_text_color"/>
                        </b-form-group>
                        <b-form-group>
                            <label>Language selection text color: <tooltip-with-image img="/img/welcome/language.png"/></label>
                            <ColorWithOpacitySelector v-model="config.language_selection_text_color"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <hr/>

                <DesignConfigSaveComponent v-model="saveName" v-bind:name-exists="this.nameExists" v-on:addConfig="(arg) => addConfig(arg)" v-on:replaceConfig="(arg) => replaceConfig(arg)"/>

            </b-card>
        </b-overlay>

        <ConfigsTable ref="configsTable" config-type="welcome_screen" v-on:dataLoaded="dataLoadedEvent" v-on:loadingStarted="loadingStartedEvent" v-on:loadConfig="loadConfig"/>
    </div>
</template>
<script>
    import ColorWithOpacitySelector from '@/views/components/ColorWithOpacitySelector'
    import {BCard, BCol, BFormGroup, BOverlay, BRow} from 'bootstrap-vue'
    import BackgroundSelector from '@/views/components/BackgroundSelector'
    import ConfigsTable from '@/views/components/config/ConfigsTable'
    import ImageSelector from '@/views/components/ImageSelector'
    import DesignConfigSaveComponent from '@/views/components/DesignConfigSaveComponent'
    import vSelect from 'vue-select'
    import TooltipWithImage from '@/views/components/TooltipWithImage'

    export default {
        components: {
            TooltipWithImage,
            DesignConfigSaveComponent,
            ImageSelector,
            ConfigsTable,
            BCard,
            BRow,
            BCol,
            BOverlay,
            ColorWithOpacitySelector,
            BFormGroup,
            BackgroundSelector,
            vSelect
        },
        data() {
            return {
                saveName: '',
                dataLoaded: false,

                imageFile: null,

                config: {
                    main_background: {},
                    window_background: {},
                    primary_text_color: {},
                    secondary_text_color: {},
                    logo_position: 0,
                    language_selection_text_color: {},
                    logo_image: null,
                    language_selection_enabled: true
                },

                logoPositions: [
                    {
                        id: 0,
                        name: 'Left'
                    },
                    {
                        id: 1,
                        name: 'Center'
                    },
                    {
                        id: 2,
                        name: 'Right'
                    }
                ]
            }
        },
        methods: {
            dataLoadedEvent() {
                this.dataLoaded = true
            },
            loadingStartedEvent() {
                this.dataLoaded = false
            },
            loadConfig(config) {
                this.config = config.config
                this.saveName = config.name
            },
            addConfig(addStatus) {
                if (!this.$refs.BackgroundSelectorBackground.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else if (!this.$refs.BackgroundSelectorWindowBackground.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else if (!this.$refs.ImageSelectorLogo.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.post('/api/management/v1/config/design/welcome_screen', {
                        status: addStatus,
                        name: this.saveName,
                        config: this.config
                    })
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config saved')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }

            },
            replaceConfig(addStatus) {
                if (!this.$refs.BackgroundSelectorBackground.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else if (!this.$refs.BackgroundSelectorWindowBackground.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else if (!this.$refs.ImageSelectorLogo.valid()) {
                    this.$printError('Configuration is not valid. Check if all settings are correctly set')
                } else {
                    const thisIns = this
                    const loadPromise = this.$http.put('/api/management/v1/config/design/welcome_screen', {
                        status: addStatus,
                        name: this.saveName,
                        config: this.config
                    })
                    loadPromise.then(function() {
                        thisIns.$printSuccess('Config replaced')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        thisIns.$refs.configsTable.loadData()
                    })
                }
            }
        },
        computed: {
            nameExists() {
                const localName = this.saveName
                if (!this.$refs.configsTable) return false
                return this.$refs.configsTable.getConfigs().some((item) => item.name === localName)
            }
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
